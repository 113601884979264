import domReady from '@wordpress/dom-ready';

domReady(() => {
  document
    .querySelectorAll('.expertises-block__tab-content > *')
    .forEach((element) => {
      element.style.display = 'none';
    });

  const firstTabContent = document.querySelector(
    '.expertises-block__tab-content > *:first-child'
  );
  if (firstTabContent) {
    firstTabContent.style.display = 'block';
  }

  const firstTabListItem = document.querySelector(
    '.expertises-block__tab-list > *:first-child'
  );
  if (firstTabListItem) {
    firstTabListItem.classList.add('active');
  }

  document.querySelectorAll('.expertises-block__tab').forEach((tab) => {
    tab.addEventListener('click', function (event) {
      event.preventDefault();
      const tabId = this.getAttribute('href');

      document.querySelectorAll('.expertises-block__tab').forEach((tabItem) => {
        tabItem.classList.remove('active');
      });

      document
        .querySelectorAll('.expertises-block__tab-content > *')
        .forEach((element) => {
          element.style.display = 'none';
        });

      this.classList.add('active');

      const selectedTabContent = document.querySelector(tabId);
      if (selectedTabContent) {
        selectedTabContent.style.display = 'block';
      }
    });
  });
});
